import React from "react";
import Helmet from "react-helmet";

/**
 * Head component
 *
 * @param {Object} props
 */
function PageHead({ title }) {
  const text = `Petroleum Club Long Beach ${title ? `— ${title}` : ""}`;

  return <Helmet title={text} />;
}

export default PageHead;
