import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import { GridWrapper } from "../components/layout-components";
import BannerClassy from "../components/banner-classy";
import { Headline } from "../components/type";
import ImageBox from "../components/john-motion/motion-box/image-box";
import { clampBuilder } from "../functions/util";
import Link from "../components/john-gatsby-helpers/link";
import LinkExternal from "../components/john-gatsby-helpers/link-external";
import { device } from "../style/theme";
import Box from "../components/john-motion/motion-box";
import PageHead from "../components/globals/page-head";

const Item = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 20vw;
  min-height: 10rem;

  p {
    margin-bottom: 0;
  }

  [data-bkg-el] {
    position: absolute;
    z-index: -1;

    &.h1 {
      font-size: ${clampBuilder(100 / 16, 320 / 16, 375, 2560)};
      color: ${({ theme }) => theme.colors.darkBlack} !important;
    }
  }

  .social-image {
    width: 7vw;
    min-width: 4rem;
    height: 7vw;
    min-height: 4rem;
  }

  h6 {
    display: inline-block;
  }
`;

const ContactGrid = styled(GridWrapper)`
  grid-auto-flow: row;
  padding: 4vw 0;
`;

const ContactItem = styled(Item)`
  grid-column-start: start;
  grid-column-end: end;
`;

const SocialGrid = styled.div`
  display: grid;
  grid-column-start: start;
  grid-column-end: end;
  grid-auto-flow: row;

  @media only screen and ${device.tablet} {
    grid-auto-flow: column;
  }
`;

const SocialItem = styled(Item)`
  width: 100%;
`;

/**
 * page-template Component
 *
 * @param {Object} props
 * @param {Object} props.data
 */
export default function PageTemplate({
  data: {
    prismicContact: {
      data: { contact_section, social_section },
    },
    prismicHomepage: {
      data: { classy_cta_action, classy_cta_action_text, classy_cta_image },
    },
  },
}) {
  return (
    <>
      <PageHead title="Contact" />
      <ContactGrid>
        {contact_section.map(({ url, contact_title, action_text }, i) => (
          <ContactItem key={i} transitionOverrides={{ delay: 0.2 * i }}>
            <Headline size="h1" data-bkg-el>
              {contact_title}
            </Headline>
            {url.includes("mailto") || url.includes("tel") ? (
              <LinkExternal href={url} blank>
                <Headline lineLength={null} size="h6">
                  {action_text}
                </Headline>
              </LinkExternal>
            ) : (
              <Link to={url}>
                <Headline lineLength={null} size="h6">
                  {action_text}
                </Headline>
              </Link>
            )}
          </ContactItem>
        ))}
        <SocialGrid>
          {social_section.map(({ url, action_text, svg }, i) => (
            <SocialItem key={i} transitionOverrides={{ delay: 0.4 * i }}>
              <ImageBox
                className="social-image"
                fluid={svg.fluid}
                data-bkg-el
              />
              <LinkExternal href={url} blank>
                <Headline lineLength={null} size="h6">
                  {action_text}
                </Headline>
              </LinkExternal>
            </SocialItem>
          ))}
        </SocialGrid>
      </ContactGrid>
      <BannerClassy
        classy_cta_image={classy_cta_image}
        classy_cta_action_text={classy_cta_action_text}
        classy_cta_action={classy_cta_action}
      />
    </>
  );
}

/**
 * pageQuery
 */
export const pageQuery = graphql`
  query ContactPage {
    prismicContact {
      id
      data {
        contact_section {
          url
          contact_title
          action_text
        }
        social_section {
          social_title
          action_text
          svg {
            fluid(
              placeholderImgixParams: { blur: 600, px: 1 }
              maxWidth: 2000
              imgixParams: { fm: "webp" }
            ) {
              ...GatsbyPrismicImageFluid
            }
          }
          url
        }
        title
      }
    }

    prismicHomepage {
      data {
        classy_cta_image {
          fluid(
            placeholderImgixParams: { blur: 600, px: 1 }
            maxWidth: 3000
            imgixParams: { fm: "webp" }
          ) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
        classy_cta_action_text
        classy_cta_action
      }
    }
  }
`;
